<template>
    <b-overlay :show='showLoader'>
        <b-card>
            <b-row>
                <b-col>
                    <h3>{{ $t("general.school_list") }}</h3>
                </b-col>
                <b-col class="text-right">
                    <b-button variant="warning" @click="$router.push({name: 'add-school'})">{{ $t("general.add_school") }}</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <vue-good-table :columns='columns' ref="table" :rows='schoollist'
                                    styleClass="vgt-table striped bordered condensed"
                                    :pagination-options="{
                                        enabled: true,
                                        perPage: pageLength,
                                    }">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'Active'">
                                <p v-if='props.row.Active === false'>
                                    NE
                                </p>
                                <p v-else-if="props.row.Active === true">
                                    DA
                                </p>
                            </span>
                            <span v-else-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1" @click="$router.push({name: 'edit-school', params: {school_id: props.row.ID}})" variant="warning"><feather-icon icon="EditIcon"/></b-button>
                                    <b-button @click="onDeleteButtonPressed(props.row.ID)" variant="danger"><feather-icon icon="Trash2Icon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>

                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >

                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>

                                    <b-dropdown
                                        variant="none"
                                        no-caret>

                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength}}) </span>
                                            <feather-icon
                                                icon="ChevronDownIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                            />
                                        </template>

                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                                            {{ length }}
                                        </b-dropdown-item>

                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>

                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-card>

        <b-modal id="confirm-deletion-modal" :cancel-disabled="true" hide-footer >
            <div class="d-block text-center">
                <p>{{ $t('general.delete_school_confirmation')}}</p>
                <div>
                    <b-button variant="warning" @click="onRejectDeletion">{{ $t('general.no') }}</b-button>
                    <b-button variant="danger" class="ml-2" @click="onAcceptDeletion">{{ $t('general.yes') }}</b-button>
                </div>
            </div>
        </b-modal>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BPagination, BDropdown, BDropdownItem, BButton, BModal} from 'bootstrap-vue'
    import {VueGoodTable} from 'vue-good-table'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BPagination, BDropdown, BDropdownItem, BButton, BModal,  VueGoodTable},
        data() {
            return {
                showLoader: false,
                schoollist: [],
                columns: [
                    
                    {
                        field: 'Name',
                        label: this.$t('general.school_title'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'SchoolType.Abbreviation',
                        label: this.$t('general.school_type'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'User.Username',
                        label: this.$t('general.school_user'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Active',
                        label: this.$t('general.active'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'ID',
                        label: '',
                        sortable: false
                    }
                ],

                pageLength: 10,
                lengths: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],

                schoolDeleteId: 0
            }
        },
        methods: {
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            },
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get('/v1/admin/sola')
                    .then(res => {
                        thisIns.schoollist = res.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            onEditButtonPressed(schoolId) {
                this.$router.push(`/school/edit/${schoolId}`)
            },
            onDeleteButtonPressed(schoolId) {
                this.schoolDeleteId = schoolId
                this.$bvModal.show('confirm-deletion-modal')
            },
            onAcceptDeletion() {
                this.deleteSchool(this.schoolDeleteId)
            },
            onRejectDeletion() {
                this.schoolDeleteId = 0
                this.$bvModal.hide('confirm-deletion-modal')
            },
            deleteSchool(schoolId) {
                const thisIns  = this
                this.$bvModal.hide('confirm-deletion-modal')
                thisIns.showLoader = true

                thisIns.$http.delete(`/v1/admin/sola/${schoolId}`)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.school_successfully_deleted')}\n`)
                        setTimeout(() => {
                            thisIns.loadData()
                        }, 500)

                        thisIns.showLoader = false
                        
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.delete_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }

        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
